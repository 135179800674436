import {postJson} from 'web/helpers/api_helpers';

export function postSendReferral({recipientEmail, amount} = {}) {
  return postJson('/api/referrals/send', {
    body: {
      recipientEmail,
      amount,
    },
  });
}
