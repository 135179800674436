import React, {ReactElement, useState, useRef} from 'react';
import classNames from 'classnames';

import {humanDollarsFromCents} from 'infra/formatters/money';
import Separator from 'web/components/separator';

interface ReferAFriendProps {
  giftValue: number;
  kickbackValue: number;
  error: string;
  referralEmail: string;
  emailButtonText: string;
  referralUrl: string;
  user: any;
  handleEmailInputChange(e: React.ChangeEvent<HTMLInputElement>): void;
  handleSubmit(e: React.FormEvent<HTMLFormElement>): void;
}

export default function ReferAFriend({
  giftValue,
  kickbackValue,
  error,
  referralEmail,
  emailButtonText,
  referralUrl,
  user,
  handleEmailInputChange,
  handleSubmit,
}: ReferAFriendProps): ReactElement {
  const [copyButtonText, setCopyButtonText] = useState('Copy');
  const referralLinkRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  const handleCopyReferralLink = (): void => {
    window.metrics.track('Referral Shared', {medium: 'copy', giverId: user?.id});

    referralLinkRef.current.select();
    navigator.clipboard.writeText(referralUrl);

    setCopyButtonText('Copied');

    setTimeout(() => setCopyButtonText('Copy'), 1500);
  };
  return (
    <>
      <h1 className="send-referrals-page__referral-form-title">Invite Friends</h1>
      <h2 className="send-referrals-page__referral-form-subtitle">
        Give {humanDollarsFromCents(giftValue)}, Get {humanDollarsFromCents(kickbackValue)}
      </h2>
      <p className="send-referrals-page__referral-form-body">
        Introduce a friend to Good Eggs with {humanDollarsFromCents(giftValue)} off their first
        order. When they use it, you’ll get a {humanDollarsFromCents(kickbackValue)} credit.
      </p>
      <div className="send-referrals-page__terms">
        Please see the <a href="/terms/referral">Terms of Use</a>.
      </div>
      {user ? (
        <div>
          <form className="send-referrals-page__email-form" onSubmit={handleSubmit}>
            <input
              className={classNames(
                'form-control',
                'split-input-button__input',
                'send-referrals-page__email-input',
                {
                  'has-error': error,
                },
              )}
              type="email"
              placeholder="Enter an email address"
              onChange={handleEmailInputChange}
              value={referralEmail}
              autoFocus
            />
            <button
              className={classNames(
                'split-input-button__button',
                'send-referrals-page__email-submit',
                {
                  'has-error': error,
                  'send-referrals-page__send-button-sage': emailButtonText === 'Invite Sent',
                },
              )}
              type="submit"
            >
              {emailButtonText}
            </button>
          </form>

          <Separator>or</Separator>

          <div className="send-referrals-page__share">
            <div className="send-referrals-page__share-text">Share your link</div>
            <input
              ref={referralLinkRef}
              className="form-control split-input-button__input send-referrals-page__share-link"
              id="referral-link"
              type="email"
              value={referralUrl}
              readOnly
            />
            <button
              className="split-input-button__button send-referrals-page__copy-button"
              onClick={handleCopyReferralLink}
            >
              {copyButtonText}
            </button>
          </div>
        </div>
      ) : (
        <div className="send-referrals-page__sign_in">
          You need to <a href="/signin">sign in</a> to refer a friend.
        </div>
      )}
    </>
  );
}
