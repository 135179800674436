import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet-async';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import segmentAnalytics from '@analytics/client';
import {shortCutoffTimeAndDay} from 'infra/formatters/time';
import Modal from 'web/components/modal';
import MarketLayout from 'web/components/market_layout';
import MarketTile from 'web/components/market_tile';
import {trackCheckoutCompleted, trackCheckoutUpdated} from 'web/helpers/checkout_metrics';
import ReferralForm from 'web/send_referrals/send_referrals_page/components/referral_form';
import {FeatureFlags} from 'web/helpers/experiments/feature_flags';
import assetPath from 'web/helpers/asset_path';

const segmentFeature = 'orderConfirmation';

export const iosUrl = `http://apple.co/1LbnTPm`;
export const androidUrl = `https://play.google.com/store/apps/details?id=com.goodeggs.mobile.production`;

const SendReferralsPage = (props) => {
  const [showOrderPlacedModal, setShowOrderPlacedModal] = useState(props.order?.id != null);
  const {features} = props;

  useEffect(() => {
    const {checkoutEvent, order, user} = props;
    // Not perfect, double-counts on page reloads,
    // but good enough for client-side conversion tracking,
    // Use the server-tracked event for greater confidence.
    if (order && order.id) {
      // Client-side conversion tracking is a necessary compromise.
      // We also track server-side.  See infra/metrics/README.md for details.
      if (props.checkoutEvent.checkoutType === 'place') {
        trackCheckoutCompleted(order, user);
      } else {
        // checkoutType is 'add' or 'edit'
        trackCheckoutUpdated(order, user, checkoutEvent);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dismissOrderPlacedModal = () => {
    setShowOrderPlacedModal(false);
  };

  const handleAppDownloadButtonClick = (destination) => () => {
    const {pathname} = window.location;

    segmentAnalytics.track('ctaClicked', {
      type: 'button',
      text: 'Easily track and manage your order with our new app',
      destination,
      pageUrl: pathname,
      feature: pathname.split('/').slice(-1)[0],
    });
  };

  return (
    <MarketLayout categories={props.categories} foodhub={props.foodhub} user={props.user}>
      <Helmet>
        <title>Send a Referral | Good Eggs</title>
      </Helmet>

      <div className="send-referrals-page">
        <ReferralForm />
      </div>
      {showOrderPlacedModal ? (
        <Modal onClose={dismissOrderPlacedModal} showCloseButton fullScreenMobile>
          <div className="order-placed-modal__contents">
            <h1 className="order-placed-modal__headline">Order Placed!</h1>
            <p className="order-placed-modal__body">
              An order confirmation has been sent to {props.user.email}.
              <br />
              You can still <a href={props.addToOrderUrl}>add to your order</a> until{' '}
              {props.orderCutoffText}.
            </p>
            <a onClick={dismissOrderPlacedModal} className="button">
              Done
            </a>
          </div>
          {features.includes(FeatureFlags.AppDownloadUpsellOnOrderConfirmation) && (
            <div className="order-placed-modal__download-app__container">
              <div className="order-placed-modal__download-app__content">
                <img
                  className="order-placed-modal__download-app__app_image"
                  src={`${assetPath(
                    '/img/web/send_referrals/mma_delivery_tracking_screenshot.png',
                  )}?auto=format`}
                />
                <div className="order-placed-modal__download-app__body">
                  <h3 className="order-placed-modal__download-app__text">
                    Easily track and manage your order with our <span>new</span> app
                  </h3>
                  <div className="order-placed-modal__download-app__app-store-buttons-container">
                    <a
                      data-testid="apple-app-store-download-button"
                      href={iosUrl}
                      onClick={handleAppDownloadButtonClick(iosUrl)}
                      target="_blank"
                    >
                      <img
                        className="order-placed-modal__download-app__app-store-image"
                        src={`${assetPath(
                          '/img/web/send_referrals/apple_app_store.png',
                        )}?auto=format`}
                      />
                    </a>
                    <a
                      href={androidUrl}
                      data-testid="google-play-store-download-button"
                      onClick={handleAppDownloadButtonClick(androidUrl)}
                      target="_blank"
                    >
                      <img
                        className="order-placed-modal__download-app__app-store-image"
                        src={`${assetPath(
                          '/img/web/send_referrals/google_play_store.png',
                        )}?auto=format`}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
          {props.recommendedProducts?.length > 0 && (
            <div className="after-checkout-recommended-products">
              <h2>
                <b>Frequently bought with items in your order</b>
              </h2>
              <br />
              <div>
                {props.recommendedProducts?.map((product, index) => (
                  <MarketTile
                    key={product._id}
                    productId={product._id}
                    quantityInBasket={0}
                    position={index}
                    segmentFeature={segmentFeature}
                    currentFulfillmentDay={product.dropoffDay}
                  />
                ))}
              </div>
            </div>
          )}
        </Modal>
      ) : null}
    </MarketLayout>
  );
};

SendReferralsPage.propTypes = {
  addToOrderUrl: PropTypes.string,
  categories: PropTypes.array,
  foodhub: PropTypes.object,
  user: PropTypes.object,
  checkoutEvent: PropTypes.shape({
    checkoutType: PropTypes.string,
    addonValue: PropTypes.number,
    daysBeforeFulfillment: PropTypes.number,
  }),
  order: PropTypes.shape({
    id: PropTypes.string,
    items: PropTypes.array,
    deliveryDetails: PropTypes.shape({
      address: PropTypes.string,
      address2: PropTypes.string,
      canLeaveAtDoor: PropTypes.bool,
      city: PropTypes.string,
      zip: PropTypes.string,
      isGift: PropTypes.bool,
    }),
    totalsForTracking: PropTypes.shape({
      revenue: PropTypes.number,
      total: PropTypes.number,
      subtotal: PropTypes.number,
      delivery: PropTypes.number,
      discount: PropTypes.number,
      referralDiscount: PropTypes.number,
      totalCheckoutValue: PropTypes.number,
      deliveryPrice: PropTypes.number,
    }),
  }),
  orderCutoffText: PropTypes.string,
};

SendReferralsPage.pageName = 'Send Referrals';
SendReferralsPage.reducer = (state, action) => {
  return MarketLayout.reducer(state, action);
};

function mapStateToProps(state) {
  let props = state;
  if (props.order && props.order.id) {
    props = {
      ...props,
      orderCutoffText: shortCutoffTimeAndDay(state.order.orderCutoff, state.settings.tzid),
      addToOrderUrl: `/account/orders/${props.order.id}/add-to-order`,
    };
  }

  return props;
}

export default connect(mapStateToProps)(SendReferralsPage);
