import React, {ReactElement, useState} from 'react';
import {connect} from 'react-redux';
import {email as validateEmail} from 'goodeggs-validators';
import {ThunkDispatch} from 'redux-thunk';
import {AnyAction} from 'redux';

import Alert from 'web/components/alert';
import {actions} from 'web/send_referrals/send_referrals_page/duck';

import ReferAFriend from './ReferAFriend';

interface ReferralGiftType {
  recipientEmail: string;
  amount: number;
}

interface ReferralsState {
  kickbackValue: number;
  giftValue: number;
}

interface StateProps {
  user: any;
  referrals: ReferralsState;
  referralUrl: string;
  features: string[];
}

interface ReferralFormState {
  features: string[];
  giftValue: number;
  kickbackValue: number;
  referralUrl: string;
  user: any;
}

interface DispatchProps {
  sendReferralGift(obj: ReferralGiftType): Promise<unknown>;
}

interface ReferralFormProps extends ReferralFormState, DispatchProps {}

const defaultError = 'Uh oh, something went wrong! Please wait a moment and try again.';

const ReferralForm = ({
  giftValue,
  kickbackValue,
  referralUrl,
  user,
  sendReferralGift,
}: ReferralFormProps): ReactElement => {
  const [referralEmail, setReferralEmail] = useState('');
  const [emailButtonText, setEmailButtonText] = useState('Send Invite');
  const [error, setError] = useState('');

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    if (!validateEmail(referralEmail)) {
      setError('Please enter a valid email!');
      return;
    }

    sendReferralGift({
      recipientEmail: referralEmail,
      amount: giftValue,
    })
      .then(() => {
        setReferralEmail('');
        setEmailButtonText('Invite Sent');

        setTimeout(() => setEmailButtonText('Send Invite'), 1500);
      })
      .catch((err) => {
        setError(err.type === 'REFERRAL_DENIED' ? err.msg : defaultError);
      });
  };

  const handleEmailInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setReferralEmail(e.target.value);
    setError('');
  };

  return (
    <div className="send-referrals-page__referral-form">
      <ReferAFriend
        giftValue={giftValue}
        kickbackValue={kickbackValue}
        user={user}
        handleSubmit={handleSubmit}
        handleEmailInputChange={handleEmailInputChange}
        referralEmail={referralEmail}
        error={error}
        emailButtonText={emailButtonText}
        referralUrl={referralUrl}
      />
      {error && <Alert type="error" heading={error} />}
    </div>
  );
};

function mapDispatchToProps(
  dispatch: ThunkDispatch<DispatchProps, ReferralFormProps, AnyAction>,
): DispatchProps {
  return {
    sendReferralGift: async ({recipientEmail, amount}: ReferralGiftType) =>
      dispatch(actions.sendReferralGift({recipientEmail, amount})),
  };
}

function mapStateToProps(state: StateProps): ReferralFormState {
  return {
    user: state.user,
    kickbackValue: state.referrals.kickbackValue,
    giftValue: state.referrals.giftValue,
    referralUrl: state.referralUrl,
    features: state.features,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReferralForm);
